import React, { useState } from "react";

/* Components */
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  TextField,
  FormControlLabel,
  FormControl,
  Switch,
  Slider,
  InputAdornment,
  Button,
  Tooltip,
  MenuItem
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SettingsResetButton from "../SettingsResetButton";
import { DEVICE_MODE, LPR_ENTRY_CAPTURE_MODE, OCCUPANCY_TYPES } from "../../../../constants";

/* Form */
import { useForm, Controller } from "react-hook-form";
import useFormHandlers from "../useFormHandlers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSettingDispatchChange } from "../index";

/* State */
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";

/* Utilities */
import * as Yup from "yup";
import _ from "lodash";
import clsx from "clsx";

/* Style */
import { useStyles } from "./styles";
import { useStyles as useRootStyles } from "../styles";

import { useFeatureFlag } from "../../../../hooks/useFeatureFlags"
import TicketLessExceptionFeeDrawer from './TicketLessExceptioFeeDrawer.js';

const LPRSettingsValidation = Yup.object().shape({
  lpr: Yup.object().shape({
    imageretention: Yup.number()
      .typeError("Must be a positive number")
      .min(1, "Must be more than 0")
      .max(60, "Must not be more than 60"),
    textretention: Yup.number()
      .typeError("Must be a positive number")
      .min(0, "Must be a positive number"),
    ticketlessexceptionfeedelay: Yup.number()
      .typeError("Must be a positive number")
      .integer("Must be a whole number")
      .min(0, "Must be between 0 to 15000 milliseconds")
      .max(15000, "Must be between 0 to 15000 milliseconds")
      .required("Required"),
    ticketlessentrycapturemode: Yup.string()
  }),
});

const marks = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 10,
    label: "10",
  },
];

const LPRSettingsForm = ({
  identifier,
  entityID,
  entityType,
  settingValues,
  settingValuesParent,
  isAdmin,
}) => {
  const classes = useStyles();
  const rootClasses = useRootStyles();
  const [lprExpanded, setLPRExpanded] = useState(true);
  const { dispatchSetting } = useSettingDispatchChange();
  const enqueueSnackbar = useEnqueueSnackbar();
  const isHotelIntegrationsEnabled = useFeatureFlag('Hotel Integrations');
  const isTicketlessLPREnabled = useFeatureFlag('LPR.Ticketless LPR');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const deviceMode = settingValues?.devicemode?.toLowerCase();
  const poeEnabled = deviceMode == 'entry' && settingValues?.poeEnabled == true ? true : false;

  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LPRSettingsValidation),
    defaultValues: {
      lpr: {
        confidencescore: settingValues?.lprConfidenceScore ?? 0,
        matchaccuracy: settingValues?.lprMatchAccuracy ?? 0,
        credential: settingValues?.lprCredential ?? false,
        calculatefee: settingValues?.lprCalculateFee ?? false,
        hotelguestautoenroll: settingValues?.lprHotelGuestAutoEnroll ?? false,
        imageretention: settingValues?.lprImageRetention ?? 1,
        textretention: settingValues?.lprTextRetention ?? 1,
        ticketless: settingValues?.lprTicketless ?? false,
        ticketlessexceptionfeedelay: settingValues?.lprTicketLessExceptionFeeDelay ?? 0,
        ticketlessentrycapturemode: settingValues?.lprTicketlessEntryCaptureMode ?? 1
      },
    },
    mode: "onChange",
  });

  const watchFields = watch();

  const { handleSelectChange, handleResetChange } = useFormHandlers(
    setValue,
    settingValues,
    entityID,
    entityType
  );

  const toggleLPRExpanded = () => {
    setLPRExpanded(!lprExpanded);
  };

  const handleConfidenceScoreChanged = async (e, targetValue) => {
    if (targetValue === settingValues.lprConfidenceScore) return;
    if (!(await dispatchSetting(entityID, "lpr.confidencescore", targetValue)))
      enqueueSnackbar("Unable to set LPR Conficence Score", {
        variant: "error",
        tag: "lprConfidenceScore",
      });
  };

  const handleMatchAccuracyChanged = async (e, targetValue) => {
    if (targetValue === settingValues.lprMatchAccuracy) return;
    if (!(await dispatchSetting(entityID, "lpr.matchaccuracy", targetValue)))
      enqueueSnackbar("Unable to set Match Acuracy", {
        variant: "error",
        tag: "lprMatchAccuracy",
      });
  };

  const handleCredentialChanged = async e => {
    const targetValue = e.target.checked;
    if (targetValue === settingValues.lprCredential) return;
    if (!(await dispatchSetting(entityID, "lpr.credential", targetValue)))
      enqueueSnackbar("Unable to set LPR as Credential", {
        variant: "error",
        tag: "lprCredential",
      });
  };

  const handleCalculateFeeChanged = async e => {
    const targetValue = e.target.checked;
    if (targetValue === settingValues.lprCalculateFee) return;
    if (!(await dispatchSetting(entityID, "lpr.calculatefee", targetValue)))
      enqueueSnackbar("Unable to set Calculate Fee", {
        variant: "error",
        tag: "lprCalculateFee",
      });
  };

  const handleHotelGuestAutoEnrollChanged = async e => {
    const targetValue = e.target.checked;
    if (targetValue === settingValues.lprHotelGuestAutoEnroll) return;
    if (!(await dispatchSetting(entityID, "lpr.hotelguestautoenroll", targetValue)))
      enqueueSnackbar("Unable to set Hotel Guest Auto Enroll", {
        variant: "error",
        tag: "lprHotelGuestAutoEnroll",
      });
  };

  const handleTicketlessLPRChanged = async e => {
    const targetValue = e.target.checked;
    if (targetValue === settingValues.lprTicketless) return;
    if (!(await dispatchSetting(entityID, "lpr.ticketless", targetValue)))
      enqueueSnackbar("Unable to set Ticketless LPR", {
        variant: "error",
        tag: "lprTicketless",
      });
  };

  const handleImageRetentionChanged = async (e, errors) => {
    const targetValue = e.target.value;
    if (targetValue === settingValues.lprImageRetention) return;
    if (errors.lpr?.imageretention) {
      setValue(e.target.name, settingValues.lprImageRetention, {
        shouldValidate: true,
      });
      return;
    }
    if (!(await dispatchSetting(entityID, "lpr.imageretention", targetValue)))
      enqueueSnackbar("Unable to set Data Retention for Image", {
        variant: "error",
        tag: "lprImageRetention",
      });
  };

  const handleTextRetentionChanged = async (e, errors) => {
    const targetValue = e.target.value;
    if (targetValue === settingValues.lprTextRetention) return;
    if (errors.lpr?.textretention) {
      setValue(e.target.name, settingValues.lprTextRetention, {
        shouldValidate: true,
      });
      return;
    }
    if (!(await dispatchSetting(entityID, "lpr.textretention", targetValue)))
      enqueueSnackbar("Unable to set Data Retention for Text", {
        variant: "error",
        tag: "lprTextRetention",
      });
  };

  const handleTextRetentionUnlimitedChanged = async e => {
    const targetValue = e.target.checked ? "0" : "1";
    if (targetValue === settingValues.lprTextRetention) return;
    if (!(await dispatchSetting(entityID, "lpr.textretention", targetValue)))
      enqueueSnackbar("Unable to set Data Retention for Text as unlimited", {
        variant: "error",
        tag: "lprTextRetentionUnlimited",
      });
  };

  const handleTicketLessExceptionFeeDelayChanged = async (e, errors) => {
    const targetValue = e.target.value;
    if (targetValue === settingValues.lprTicketLessExceptionFeeDelay) return;
    if (errors.lpr?.ticketlessexceptionfeedelay) {
      setValue(e.target.name, settingValues.lprTicketLessExceptionFeeDelay, {
        shouldValidate: true,
      });
      return;
    }
    if (!(await dispatchSetting(entityID, "lpr.ticketlessexceptionfeedelay", targetValue))) {
      enqueueSnackbar("Unable to set Ticketless Exception Fee Delay for Text", {
        variant: "error",
        tag: "lprTicketLessExceptionFeeDelay",
      });
      setValue(e.target.name, settingValues.lprTicketLessExceptionFeeDelay);
    }
  };

  const handleEntryCaptureModeSelect = async (e, selected) => {
     await handleSelectChange(errors, e, selected);
  };

  return (
    <Grid container className={rootClasses.panelRoot} data-testid={identifier}>
      <Accordion
        data-tag="lpr"
        expanded={lprExpanded}
        TransitionProps={{ unmountOnExit: true }}
        className={rootClasses.fullWidthAccordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={toggleLPRExpanded}
        >
          <Typography className={rootClasses.heading}>LPR</Typography>
          <Typography className={rootClasses.secondaryHeading}></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container
            justify="flex-end"
            spacing={2}>
            {/* Hide "Minimum plate read confience score" until ERS will be ready */}
            {/* Need to adjust break points */}
            {/* <Grid container item xs={12} sm={6}>
              <Grid item xs={10} sm={6}>
                <Typography>Minimum plate read confidence score</Typography>
              </Grid>
              <Grid item xs={2} sm={6}>
                <SettingsResetButton
                  entityID={entityID}
                  entityType={entityType}
                  settingName="lpr.confidencescore"
                  currentValue={watchFields.lpr.confidencescore}
                  originalValue={settingValuesParent.lprConfidenceScore}
                  onClick={() => {
                    handleResetChange(
                      "lpr.confidencescore",
                      settingValuesParent.lprConfidenceScore
                    );
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                data-tag="lpr-confidence-score"
                className={classes.slider}
              >
                <Controller
                  name="lpr.confidencescore"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      formlabel="ConfidenceScore"
                      role="lpr-confidence-score"
                    >
                      <Slider
                        {...field}
                        step={1}
                        min={1}
                        max={10}
                        valueLabelDisplay="on"
                        marks={marks}
                        onChange={(e, value) => {
                          field.onChange(value);
                        }}
                        onChangeCommitted={(e, value) => {
                          field.onChange(value);
                          handleConfidenceScoreChanged(e, value);
                        }}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid> */}
            <Grid container item xs={12} lg={6}>
              <Grid item xs={10} lg={3}>
                <Typography>Match Accuracy</Typography>
              </Grid>
              <Grid item xs={2} lg={9}>
                <SettingsResetButton
                  entityID={entityID}
                  entityType={entityType}
                  settingName="lpr.matchaccuracy"
                  currentValue={watchFields.lpr.matchaccuracy}
                  originalValue={settingValuesParent.lprMatchAccuracy}
                  onClick={() => {
                    handleResetChange(
                      "lpr.matchaccuracy",
                      settingValuesParent.lprMatchAccuracy
                    );
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={10}
                lg={10}
                data-tag="lpr-match-accuracy"
                className={classes.slider}
              >
                <Controller
                  name="lpr.matchaccuracy"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      formlabel="ConfidenceScore"
                      role="lpr-match-accuracy"
                    >
                      <Slider
                        {...field}
                        step={1}
                        min={1}
                        max={10}
                        valueLabelDisplay="on"
                        marks={marks}
                        onChange={(e, value) => {
                          field.onChange(value);
                        }}
                        onChangeCommitted={(e, value) => {
                          field.onChange(value);
                          handleMatchAccuracyChanged(e, value);
                        }}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} lg={6}>
              <Grid item xs={12} lg={12}>
                <Controller
                  name="lpr.credential"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      role="lpr-credential-toggle"
                      label="LPR as Credential"
                      control={
                        <Switch
                          {...field}
                          id="lprcredential"
                          color="primary"
                          onChange={e => {
                            field.onChange(e.target.checked);
                            handleCredentialChanged(e);
                          }}
                          checked={field.value}
                          data-checked={field.value}
                        />
                      }
                    />
                  )}
                />
                <SettingsResetButton
                  entityID={entityID}
                  entityType={entityType}
                  settingName="lpr.credential"
                  currentValue={watchFields.lpr.credential}
                  originalValue={settingValuesParent.lprCredential}
                  onClick={() => {
                    handleResetChange(
                      "lpr.credential",
                      settingValuesParent.lprCredential
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <Controller
                  name="lpr.calculatefee"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      role="lpr-calculate-fee-toggle"
                      label="Calculate exit fee using LPR"
                      control={
                        <Switch
                          {...field}
                          id="lprcalculatefee"
                          color="primary"
                          onChange={e => {
                            field.onChange(e.target.checked);
                            handleCalculateFeeChanged(e);
                          }}
                          checked={field.value}
                          data-checked={field.value}
                        />
                      }
                    />
                  )}
                />
                <SettingsResetButton
                  entityID={entityID}
                  entityType={entityType}
                  settingName="lpr.calculatefee"
                  currentValue={watchFields.lpr.calculatefee}
                  originalValue={settingValuesParent.lprCalculateFee}
                  onClick={() => {
                    handleResetChange(
                      "lpr.calculatefee",
                      settingValuesParent.lprCalculateFee
                    );
                  }}
                />
              </Grid>
              {isHotelIntegrationsEnabled && (
                <Grid item xs={12} lg={12}>
                  <Controller
                    name="lpr.hotelguestautoenroll"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        role="lpr-hotel-guest-auto-enroll-toggle"
                        label="Hotel Guest Auto Enroll"
                        control={
                          <Switch
                            {...field}
                            id="lprhotelguestautoenroll"
                            color="primary"
                            onChange={e => {
                              field.onChange(e.target.checked);
                              handleHotelGuestAutoEnrollChanged(e);
                            }}
                            checked={field.value}
                            data-checked={field.value}
                          />
                        }
                      />
                    )}
                  />
                  <SettingsResetButton
                    entityID={entityID}
                    entityType={entityType}
                    settingName="lpr.hotelguestautoenroll"
                    currentValue={watchFields.lpr.hotelguestautoenroll}
                    originalValue={settingValuesParent.lprHotelGuestAutoEnroll}
                    onClick={() => {
                      handleResetChange(
                        "lpr.hotelguestautoenroll",
                        settingValuesParent.lprHotelGuestAutoEnroll
                      );
                    }}
                  />
                </Grid>
              )}
              {((entityType?.toLowerCase() === "facility" || deviceMode === DEVICE_MODE.EXIT.toLowerCase()
                || (deviceMode === DEVICE_MODE.ENTRY.toLowerCase())) && isTicketlessLPREnabled) && (
                  <Grid item xs={12} lg={12}>
                    <Controller
                      name="lpr.ticketless"
                      control={control}
                      render={({ field }) => (
                        <Tooltip
                          placement="bottom-start"
                          title={poeEnabled ? "Ticketless LPR and Pay on Entry cannot be used at the same time." : ""}
                        >
                          <FormControlLabel
                            role="lpr-ticketless-toggle"
                            label="Ticketless LPR"
                            control={
                              <Switch
                                {...field}
                                id="lprticketless"
                                color="primary"
                                onChange={e => {
                                  field.onChange(e.target.checked);
                                  handleTicketlessLPRChanged(e);
                                }}
                                checked={field.value}
                                data-checked={field.value}
                                disabled={poeEnabled}
                              />
                            }
                          />
                        </Tooltip>
                      )}
                    />
                    {!poeEnabled &&
                      <SettingsResetButton
                        entityID={entityID}
                        entityType={entityType}
                        settingName="lpr.ticketless"
                        currentValue={watchFields.lpr.ticketless}
                        originalValue={settingValuesParent.lprTicketless}
                        onClick={() => {
                          handleResetChange(
                            "lpr.ticketless",
                            settingValuesParent.lprTicketless
                          );
                        }}
                      />
                    }
                  </Grid>
                )}

            </Grid>
            {entityType?.toLowerCase() === "facility" && (
              <>
                <Grid item xs={12} lg={12}>
                  <Typography>LPR Data Retention</Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Grid
                    container
                    item
                    xs={12}
                    lg={12}
                    className={clsx([classes.dataRetention])}
                  >
                    <Grid item xs={8} lg={4}>
                      <Controller
                        name="lpr.imageretention"
                        control={control}
                        render={({ field }) => (
                          <FormControl
                            fullWidth
                            formlabel="ImageRetention"
                            role="lpr-image-retention"
                          >
                            <TextField
                              {...field}
                              id="imageretention"
                              label="Image"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 1,
                                  step: 1,
                                  max: 60,
                                },
                                inputMode: "numeric",
                                endAdornment: (
                                  <InputAdornment position="start">
                                    day(s)
                                  </InputAdornment>
                                ),
                              }}
                              onBlur={e => {
                                handleImageRetentionChanged(e, errors);
                              }}
                              variant="outlined"
                              error={!!errors.lpr?.imageretention}
                              helperText={
                                errors.lpr?.imageretention &&
                                errors.lpr?.imageretention.message
                              }
                            />
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                  {watchFields.lpr.textretention !== "0" && (
                    <>
                      <Grid
                        container
                        item
                        xs={12}
                        lg={12}
                        className={clsx([classes.textRetention])}
                      >
                        <Grid item xs={8} lg={4}>
                          <Controller
                            name="lpr.textretention"
                            control={control}
                            render={({ field }) => (
                              <FormControl
                                fullWidth
                                formlabel="TextRetention"
                                role="lpr-text-retention"
                              >
                                <TextField
                                  {...field}
                                  id="lprtextretention"
                                  label="Text"
                                  type="number"
                                  InputProps={{
                                    inputProps: {
                                      min: 1,
                                      step: 1,
                                    },
                                    inputMode: "numeric",
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        day(s)
                                      </InputAdornment>
                                    ),
                                  }}
                                  onBlur={e => {
                                    handleTextRetentionChanged(e, errors);
                                  }}
                                  disabled={
                                    watchFields.lpr.textretention === "0"
                                      ? true
                                      : false
                                  }
                                  variant="outlined"
                                  error={!!errors.lpr?.textretention}
                                  helperText={
                                    errors.lpr?.textretention &&
                                    errors.lpr?.textretention.message
                                  }
                                />
                              </FormControl>
                            )}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Grid container className={clsx([classes.unlimited])}>
                    <Grid item xs={11} lg={4}>
                      <Controller
                        name="lpr.textretention"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            role="lpr-unlimited-text-checkbox"
                            label="Unlimited for Text"
                            control={
                              <input
                                {...field}
                                className={clsx(
                                  "text-unlimited-checkbox",
                                  classes.unlimitedcheckbox
                                )}
                                type="checkbox"
                                checked={field.value === "0" ? true : false}
                                data-checked={field.value === "0" ? true : false}
                                onChange={e => {
                                  field.onChange(e.target.checked ? "0" : "1");
                                  handleTextRetentionUnlimitedChanged(e);
                                }}
                              />
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid item xs={12} lg={6}>
              {((deviceMode === DEVICE_MODE.ENTRY.toLowerCase() || entityType?.toLowerCase() === OCCUPANCY_TYPES.Facility.toLowerCase())
                && isTicketlessLPREnabled) && (
                  <Grid item xs={12} lg={12} className={clsx([classes.entrycapturemodeSelector], [classes.resetButton])} style={{
                    visibility:
                      watchFields.lpr.ticketless === true
                        ? 'visible'
                        : 'hidden'
                  }}>
                    <Controller
                      name="lpr.ticketlessentrycapturemode"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className={classes.entrycapturemodeSelector}
                          data-id="lprTicketlessEntryCaptureMode"
                          id="lprTicketlessEntryCaptureMode"
                          name="lpr.ticketlessentrycapturemode"
                          label={<Typography>Entry Capture Mode</Typography>}
                          select
                          variant="outlined"
                          onChange={handleEntryCaptureModeSelect}
                          disabled={poeEnabled}
                          inputProps={{
                            ["data-testid"]: "ticketlessentrycapturemode-select",
                          }}
                        >
                          <MenuItem value={LPR_ENTRY_CAPTURE_MODE.BeforeEntry}>Before Entry</MenuItem>
                          <MenuItem value={LPR_ENTRY_CAPTURE_MODE.AfterEntry}>After Entry</MenuItem>
                        </TextField>
                      )}
                    />
                    {!poeEnabled && <SettingsResetButton
                      entityID={entityID}
                      entityType={entityType}
                      settingName="lpr.ticketlessentrycapturemode"
                      currentValue={watchFields.lpr.ticketlessentrycapturemode}
                      originalValue={settingValuesParent.lprTicketlessEntryCaptureMode}
                      onClick={() => {
                        handleResetChange(
                          "lpr.ticketlessentrycapturemode",
                          settingValuesParent.lprTicketlessEntryCaptureMode
                        );
                      }}
                    />
                    }
                  </Grid>
                )}
              {(entityType?.toLowerCase() === "facility" || deviceMode === DEVICE_MODE.EXIT.toLowerCase())
                && isTicketlessLPREnabled && (
                  <>
                    <Grid data-tag="exceptionfee" className={clsx([classes.ticketLessFee])} item xs={12} lg={8} style={{
                      visibility:
                        watchFields.lpr.ticketless === true
                          ? 'visible'
                          : 'hidden'
                    }}>
                      <Button
                        onClick={() => { setDrawerOpen(true) }}
                        fullWidth
                        variant="contained"
                        type="submit"
                        color="primary"
                        name="lprexceptionfeebtn"
                        data-testid="lpr-exception-fee-btn"
                      >
                        Ticketless Exception Fee
                      </Button>
                      <TicketLessExceptionFeeDrawer
                        drawerOpen={drawerOpen}
                        setDrawerOpen={setDrawerOpen}
                        entityID={entityID}
                        settingValues={settingValues}
                        entityType={entityType}
                        settingValuesParent={settingValuesParent}
                      />
                    </Grid>
                    <Grid
                      className={clsx([classes.ticketLessFeeDelay], [classes.resetButton])}
                      data-tag="exceptionfeedelay"
                      item
                      xs={12}
                      lg={8}
                      style={{
                        visibility:
                          watchFields.lpr.ticketless === true
                            ? 'visible'
                            : 'hidden'
                      }}
                    >
                      <Controller
                        name="lpr.ticketlessexceptionfeedelay"
                        control={control}
                        render={({ field }) => (
                          <Tooltip
                            placement="top-end"
                            title="Use this setting to delay fee calculation up to 15 seconds. This is used for scenarios where the device gets armed before the camera sent a read."
                          >
                            <FormControl
                              fullWidth
                              formlabel="TicketLessExceptionFeeDelay"
                              role="lpr-ticketless-exception-feedelay"
                            >
                              <TextField
                                {...field}
                                id="lprticketlessexceptionfeedelay"
                                label="Ticketless fee calculation delay"
                                variant="outlined"
                                onBlur={e => {
                                  handleTicketLessExceptionFeeDelayChanged(e, errors);
                                }}
                                error={!!errors.lpr?.ticketlessexceptionfeedelay}
                                helperText={
                                  (errors.lpr?.ticketlessexceptionfeedelay &&
                                    errors.lpr?.ticketlessexceptionfeedelay.message) || "in milliseconds"
                                }
                              />
                            </FormControl>
                          </Tooltip>
                        )}
                      />
                      <SettingsResetButton
                        entityID={entityID}
                        entityType={entityType}
                        settingName="lpr.ticketlessexceptionfeedelay"
                        currentValue={watchFields.lpr.ticketlessexceptionfeedelay}
                        originalValue={settingValuesParent.lprTicketLessExceptionFeeDelay}
                        onClick={() => {
                          handleResetChange(
                            "lpr.ticketlessexceptionfeedelay",
                            settingValuesParent.lprTicketLessExceptionFeeDelay
                          );
                        }}
                      />
                    </Grid>
                  </>
                )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default LPRSettingsForm;
